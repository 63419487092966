<template>
    <div class="AccessControl">
        <div class="center" style="font-size:14px;margin-bottom:20px">街道/社区出入通行证领取表，请如实填写!</div>
        <div class="center"><van-image round width="80" height="80" src=""/></div>
         <van-form @submit="onSubmit">
           <van-field
             v-model="dataForm.name" name="name" label="姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"/>
           <van-field
             v-model="dataForm.type" name="type" label="类型" :rules="[{ required: true, message: '请选择类型' }]">
                  <template #input>
                     <van-radio-group v-model="dataForm.type" direction="horizontal">
                       <van-radio name="1">居民</van-radio>
                       <van-radio name="2">访客</van-radio>
                     </van-radio-group>
                  </template>
                  </van-field>
           <van-field :value="dataForm.IdType" name="IdType" label="证件类型" :rules="[{ required: true, message: '请选择证件类型' }]" @click="IDTypeShow=true" is-link/>
           <van-field
             v-model="dataForm.ID" name="ID" label="证件号" maxlength="18"
             :rules="[{ required: true, message: '请填写证件号' }]"/>
           <van-field
             v-model="dataForm.mobile" name="mobile" label="手机号" maxlength="11" @input="dataForm.mobile=dataForm.mobile.replace(/\D/g,'')"
             :rules="[{ required: true, message: '请填写手机号' }]"/>
           <van-field
             v-model="dataForm.address" name="address" label="居住房屋/来访地址"
              :rules="[{ required: true, message: '请填写居住房屋/来访地址' }]"/>
           <van-field
             v-model="dataForm.temperature" name="temperature" label="体温"
              :rules="[{ required: true, message: '请填写体温' }]"/>
           <van-field
             :value="dataForm.healthCode" name="healthCode" label="健康码"
             :rules="[{ required: true, message: '请选择健康码' }]"
            @click="healthShow=true" is-link/>
           <div class="btn">
              <van-button round block  style="width:200px"  type="info" native-type="submit">确认提交</van-button>
           </div>
         </van-form>
         <van-popup v-model="IDTypeShow" position="bottom">
               <van-picker title="证件类型" show-toolbar :columns="IDList"
                 @confirm="IDTypeShow=false"
                 @cancel="IDTypeShow=false"
                 @change="IDTypeChange"/>
         </van-popup>
         <van-popup v-model="healthShow" position="bottom">
              <van-picker title="健康码" show-toolbar :columns="healthList"
                  @confirm="healthShow=false"
                  @cancel="healthShow=false"
                  @change="healthChange"/>
         </van-popup>
         <!-- 实时健康码 -->
         <div>
              <div class="center date" v-html="formateTime(date)"></div>
              <div class="center" style="color:#70B603;font-size:25px;font-weight:700">2日-1日还能出入10次</div>
              <div><img src="" alt=""></div>
              <div class="ptleft">整户管控期每1天1次：目前：10次</div>
              <div class="ptleft">最近小区出入记录：</div>
               <table style="width:100%;pading:10px">
                 <tr class="fs18">
                   <th>日期</th>
                   <th>进出场所</th>
                   <th>进出类型</th>
                 </tr>
                 <tr class="center fs18">
                   <td>2022-04-12</td>
                   <td>善闲社区六栋小区2号楼3</td>
                   <td>进入</td>
                 </tr>
               </table>
         </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dataForm:{
                name:'',
                type:"",
                IdType:'',
                ID:'',
                mobile:'',
                temperature:'',
                healthCode:'',    
            },
            IDList:['','身份证','其他'],
            healthList:['','绿码','黄码','红码'],
            IDTypeShow:false,
            healthShow:false,
            time:{
              month:'',
              day:'',
              hour:'',
              minute:'',
              second:''
            },
            date:new Date().getTime()
        }
    },
    created(){
      this.loadTime()
    },
    methods:{
         onSubmit(values) {
           console.log('submit', this.dataForm);
           console.log('1111',values);
         },
         IDTypeChange(picker,value){
                this.dataForm.IdType=value
         },
         healthChange(picker,value){
                this.dataForm.healthCode=value
         },
         loadTime(){
           var that =this
            setInterval(()=>{
              that.date +=1000
            },1000)
         },
         formateTime(time){  //实时时间
           var date = new Date(time);
           var year   =  date.getFullYear();
           var month  =  date.getMonth() + 1 < 10 ? '0' +(date.getMonth() +1) :date.getMonth() + 1;
           var day    =  date.getDate() < 10 ? '0'+ date.getDate():date.getDate()
           var hour   =  date.getHours()    < 10? '0'+date.getHours():date.getHours()
           var minute =  date.getMinutes() < 10 ?'0'+date.getMinutes():date.getMinutes();
           var second =  date.getSeconds() < 10 ?'0'+date.getSeconds():date.getSeconds()
           return(`
           <div style="font-size:40px;font-weight:700"><span>${month}</span>月<span>${day}</span>日</div> 
           <div style="font-size:50px;font-weight:700"><span>${hour}</span>:<span>${minute}</span>:<span>${second}</span></div>
           <div class="center sign"><p>已完成</p><p>今日核酸</p><p>采样</p></div> 

           `)
         }
    },
    destroyed(){
      this.loadTime()
    }
}
</script>
<style scoped>
   .center{
     text-align: center;
   }
   .date{
     position: relative;
   }
   .date >>> .sign{
     position: absolute;
     top: -10px;
     right: 90px;
     width: 100px;
     height: 100px;
     font-weight: 700;
     border:2px solid #70B603 ;
     border-radius: 50%;
     color: #70B603;
     overflow: hidden;
     transform: rotate(45deg);
   }
   .ptleft{
     padding-left: 20px;
     font-size: 20px;
   }
   .fs18{
     font-size: 18px;
   }
  .btn{
      padding-top: 80px;
      display: flex;
      justify-content: center;
    }
</style>